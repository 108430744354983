import React, { useState, useEffect, useCallback } from 'react';
import { useSpring, animated } from '@react-spring/web';
import './App.css';

const App = () => {
  const [time, setTime] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  const handleUserInteraction = useCallback(() => {
    if (!gameOver) {
      setGameOver(true);
    }
  }, [gameOver]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!gameOver) {
        setTime(prevTime => prevTime + 1);
      }
    }, 1000);

    window.addEventListener('mousemove', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };
  }, [gameOver, handleUserInteraction]);

  const resetGame = () => {
    setTime(0);
    setGameOver(false);
  };

  const shareMessage = `あなたの何もしなかった時間 ${time} 秒! #DoNotInteractGame`;
  const shareOnTwitter = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}`;

  const timeSpring = useSpring({
    number: time,
    from: { number: 0 },
    config: { duration: 1000 }
  });

  return (
    <div className="App">
      <header className="App-header">
        <h1>Do Not Interact Game</h1>
        {gameOver ? (
          <>
            <p>Game Over! Your time: <animated.span>{timeSpring.number.to(n => n.toFixed(0))}</animated.span> seconds</p>
            <button onClick={resetGame}>Restart</button>
            <div className="share-buttons">
              <a href={shareOnTwitter} target="_blank" rel="noopener noreferrer">Share on Twitter</a>
            </div>
          </>
        ) : (
          <p>Time: <animated.span>{timeSpring.number.to(n => n.toFixed(0))}</animated.span> seconds</p>
        )}
      </header>
      <footer className="App-footer">
        <p>Powered by Nyaaan.net</p>
      </footer>
    </div>
  );
};

export default App;